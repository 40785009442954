<template>
  <b-container class="container">
    <Loadable :items="path" />
    <b-row v-if="path" class="mb-2">
      <b-col class="mr-auto tag-wrapper" cols="auto"
        ><PathBadge />
        <Subtitles
          v-if="showLanguages"
          :languages="subtitleLanguages"
          :popupEnabled="true"
      /></b-col>
    </b-row>
    <b-row v-if="path" class="mb-4">
      <b-col
        xl="8"
        lg="10"
        class="d-flex align-items-center justify-content-between"
      >
        <h1>{{ path.attributes.name }}</h1>
        <ToggleStarred
          :model="'path'"
          :id="path.id"
          :starred="starred"
          :starText="$t('star-course')"
          :unstarText="$t('unstar-course')"
          :starredText="$t('series-starred')"
          :unstarredText="$t('series-unstarred')"
          highlighted="true"
        />
        <!-- <b-row align-v="center">
          <b-col cols="auto">
            <h1 class="mb-2">{{ path.attributes.name }}</h1>
          </b-col>
          <b-col class="align-self-end" cols="auto">
            <ToggleStarred
              :model="'path'"
              :id="path.id"
              :starred="starred"
              :starText="$t('star-course')"
              :unstarText="$t('unstar-course')"
              :starredText="$t('series-starred')"
              :unstarredText="$t('series-unstarred')"
            />
          </b-col>
        </b-row> -->
      </b-col>
      <b-col xl="3" lg="12"></b-col>
    </b-row>
    <b-row v-if="path">
      <b-col xl="8" lg="12">
        <b-img
          class="path-thumbnail mb-4 mb-xl-5"
          :src="thumbnail"
          alt="Thumbnail"
          fluid-grow
        ></b-img>
        <div
          class="path-content mb-4 mb-xl-5"
          v-html="renderMd(path.attributes.content)"
        ></div>
      </b-col>
      <b-col xl="4" lg="12">
        <PathExplanation class="mb-4" />
        <PathBenefits :benefits="path.benefits" />
      </b-col>
      <b-col xl="9" lg="12">
        <div class="path-results mt-4 mb-3">
          <h3 class="--rule mb-4">
            {{
              isAdmin
                ? "V tejto Sérii sa dozviete:"
                : "V tejto Sérii sa dozvieš:"
            }}
          </h3>
          <b-row>
            <b-col cols="auto" class="pr-2 pr-sm-3">
              <div class="z-icon-wrapper -middle mr-4 w-background-primary">
                <svg-bookmark-notebook-icon class="w-color-white" />
              </div>
            </b-col>
            <b-col>
              <div
                class="path-results__text font-weight-bold"
                v-html="renderMd(this.path.attributes.results)"
              ></div>
            </b-col>
          </b-row>
        </div>
        <PathLessons :path="path" />
        <section
          v-if="
            path.attributes.partners.data &&
            path.attributes.partners.data.length
          "
        >
          <LessonContentDetailPartners
            :partners="path.attributes.partners.data"
          />
        </section>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "/utils/axios";
import MarkdownIt from "markdown-it";
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import { getCourseThumbnailUrl } from "/utils/helpers";

import * as Sentry from "@sentry/vue";

const api = {
  path: async (slug) => {
    const response = await axios.getAuth("paths", {
      params: {
        populate: [
          "thumbnail",
          "partners.logo",
          "courses",
          "courses.thumbnail",
          "subtitleLanguages.flag",
        ],
        filters: {
          slug,
        },
      },
    });

    if (isEmpty(response.data)) {
      return null;
    }

    return response.data[0];
  },
};

export default {
  data() {
    return {
      path: null,
      md: null,
      subtitleLanguages: undefined,
      showLanguages: false,
    };
  },

  components: {
    ToggleStarred: () => import("/components/ToggleStarred.vue"),
    PathBadge: () => import("/components/Path/PathBadge.vue"),
    PathLessons: () => import("/components/Path/PathLessons.vue"),
    PathExplanation: () => import("/components/Path/PathExplanation.vue"),
    PathBenefits: () => import("/components/Path/PathBenefits.vue"),
    LessonContentDetailPartners: () =>
      import("/components/Lesson/Content/LessonContentDetailPartners.vue"),
    "svg-bookmark-notebook-icon": () =>
      import("/assets/icons/bookmarkNotebook.svg?inline"),
    Subtitles: () => import("/components/Subtitles/Subtitles.vue"),
    Loadable: () => import("/components/Loadable.vue"),
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
    starred() {
      return Boolean(
        this.user &&
          (this.user.favoritePaths || []).find(({ id }) => id === this.path.id)
      );
    },
    thumbnail() {
      return getCourseThumbnailUrl(this.path, "original");
    },
  },

  async created() {
    this.$eventLogger.userLog("path.view", {
      path_slug: this.$route.params.pathSlug,
    });

    this.md = new MarkdownIt();
    await this._loadPath();
  },

  methods: {
    renderMd(input) {
      return this.md.render(input);
    },

    async _loadPath() {
      try {
        const path = await api.path(this.$route.params.pathSlug);
        this.path = path;
        this.subtitleLanguages = path.attributes.subtitleLanguages.data;
        this.showLanguages = this.subtitleLanguages
          ? this.subtitleLanguages.length !== 0
          : false;

        // Process benefits
        this.path.benefits = this._processBenefits(
          this.path.attributes.benefits
        );
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    _processBenefits(benefitString) {
      let benefitArray = benefitString.split("\n").filter((benefit) => {
        benefit = benefit.trim();
        return benefit.length;
      });

      const benefits = [];

      for (let i = 0; i < benefitArray.length; i += 2) {
        const benefit = {};
        benefit.type = benefitArray[i];
        benefit.description = benefitArray[i + 1];
        benefits.push(benefit);
      }

      return benefits;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  line-height: 1.5rem;
  font-size: 1.5rem;
  margin: 0;
}

.path-thumbnail {
  border-radius: 0.75rem;
}

.path-content,
.path-results__text {
  margin-right: 1rem;
}

.path-results__text::v-deep ul {
  padding-inline-start: 1rem;
}

/* TODO: Discuss universal change */
/* REVIEW: Exceptions from the current rules. Better rules are needed. */
.z-icon-wrapper > svg {
  min-height: 1.875rem;
}

.tag-wrapper {
  display: flex;
  gap: 0.5rem;
  max-height: 2.0625rem;
}

section,
::v-deep section {
  padding: 2rem 0;

  .z-icon-wrapper {
    margin-right: 1.5rem;
  }
}
</style>
